<script>
export default {
    data: function () {
        return {

        }
    },
    props: ['photos', 'isGreyImg', 'app'],
    methods: {
        productSetup: function (photo) {
            this.app.resetBasket()
            this.app.basketStep = 1;

            //todo: move it to function
            this.app.basket.image.number = photo.number
            this.app.basket.image.img = photo.img

            window.scrollTo(0, 0);
        },
        openGallery: function (photo) {
            this.app.isGallery = true;
            this.app.galleryPhoto = photo;
            window.scrollTo(0, 100);
        },
    }
}
</script>

<template>
    <!-- list photo start -->
        <div class="row-holder">
            <div class="col" v-for="(photo, number) in this.photos">
                <div class="bg-wrapper">
                    <div class="image-holder b-shadow" :class="{ 'gray-img': this.isGreyImg }">
                        <img class="not-retina" :src="photo" alt="image description">
                        <img class="retina" :src="photo" alt="image description">
                        <!-- fixme
                                                            <img class="retina" src="/images/photos@2x.jpg" alt="image description">
                        -->
                        <div class="more-info b-shadow">
                            <div class="id-number">
                                <span>#{{ number }}</span>
                            </div>
                            <div class="title-block">
                                <a href="javascript:void(0)" @click="this.productSetup({number, img:photo})">{{ trans("Vælg billede") }}</a>
                            </div>
                            <div class="zoom">
                                <a href="javascript:void(0)" @click="this.openGallery({number, img:photo})"><i class="icon-zoom"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- list photo end -->
</template>

<style scoped>

</style>
